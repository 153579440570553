.c13n-image-click {
  width: 100px;
}

.c13n-image-modal {
  width: 90vw;
  height: 75vh;
  background-repeat: no-repeat;
  background-position: center;
}
