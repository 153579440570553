.content-body {
  grid-row: 1/2;
  grid-column: 2/3;
  height: 100%;
}

@media screen and (max-width: 618px) {
  .content-body{
    grid-column: 1/3;
    width: 100%;
  }
}

.chatHistoryExpandForMobile {
  margin-right: auto;
  font-size: 45px;
  cursor: pointer;
  z-index: 97;
  color: white;
  padding: 10px;
  border-radius: 3px;
}
