.search-users {
  display: grid;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
}

.search-users-header {
  height: 4rem;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
}

.search-users-header-text {
  font-size: 18px;
  grid-column-start: 2;
}

.search-users-contents {
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
}

.search-wrapper {
  display: block;
  width: 90%;
  margin-top: 3%;
  margin-bottom: 2%;
  max-height: 50vh;
  overflow-y: auto;
}

.users-search {
  width: 100%;
  display: flex;
  border-top: none;
  border-left: none;
  border-right: none;
}

.error-validation {
  color: #b40e0e;
}

.search-users-description {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
}

.search-users-container {
  padding: 10px;
}

.search-users-containerDv {
  user-select: text;
  font-weight: bold;
}

.search-users-containerAddress {
  display: inline;
  user-select: text;
  font-size: 75%;
}

.search-users-button {
  display: inline-block;
}

.search-users-userDeleteOutlined {
  font-size: 125%;
}

.search-users-userAddOutlined {
  font-size: 125%;
}

.search-users-address {
  display: inline;
  user-select: text;
  font-size: 75%;
}

.search-users-inputModal {
  border-top: none;
  border-left: none;
  border-right: none;
}
