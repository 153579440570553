.sidePanel {
  grid-row: 1/2;
  grid-column: 1/2;
  display: grid;
  /* width: 13rem; */
  grid-template-rows: repeat(2, minmax(min-content, max-content)) auto;
  height: 100%;
  background-color: rgb(23, 23, 37);
  user-select: none;
  border-right: 2px solid #333333;
}

.sidePanel-mobile {
  background-color: rgb(23, 23, 37);
}

@media screen and (min-width: 600px) {
  .fullscreen {
    display: none;
  }
}

@media screen and (max-width: 618px) {
  .sidePanel {
    display: none;
  }

  .sidePanel-mobile {
    display: grid;
    grid-template-columns: 1/3;
    /* width: 15rem; */
    z-index: 3;
    background-color: rgb(23, 23, 37);
  }
}

@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: nowrap !important;
  }
}

.side-user-info {
  border-bottom: 1px solid #333333;
  display: grid;
  height: 7rem;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: minmax(min-content, max-content) auto;
  padding: 0.1rem 0.8rem 0.1rem 0.2rem;
  background-color: rgb(23, 23, 37);
}

.side-user-info-icon {
  grid-row: 1/3;
  grid-column: 1/2;
  align-self: start;
}

.side-user-info-name {
  white-space: nowrap;
  cursor: pointer;
  grid-row: 1/2;
  grid-column: 2/3;
  align-self: center;
  justify-self: start;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, minmax(min-content, max-content));
}

.side-user-info-name-text {
  font-size: 20px;
  grid-row: 1/2;
  grid-column: 1/2;
}

.side-user-info-name-navigationDots {
  grid-row: 2/3;
  grid-column: 1/2;
  writing-mode: vertical-lr;
  font-size: 24px;
  position: relative;
  left: -8px;
}

.currentFundsWrapper {
  font-size: 17px;
  line-height: 100%;
  grid-row: 2/3;
  grid-column: 2/3;
  align-items: center;
  justify-items: end;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(3, 1fr);
}

#currentFundsWrapperChange {
  font-size: 17px;
  font-weight: bold;
  grid-row: 1/2;
  grid-column: 1/2;
  align-items: end;
}

.currentFundsWrapperChangeLogo {
  grid-row: 1/2;
  grid-column: 2/3;
  font-size: 12px;
}

.currentFundsWrapperSatoshis {
  font-weight: bold;
  font-size: 17px;
  grid-row: 2/3;
  grid-column: 1/2;
}

.currentFundsWrapperSatoshisLogo {
  font-size: 12px;
  grid-row: 2/3;
  grid-column: 2/2;
}

.currentFundsWrapperEuro {
  opacity: 0.5;
  font-size: 15px;
  grid-row: 3/4;
  grid-column: 2/1;
  justify-self: end;
}

.currentFundsWrapperEuroLogo {
  opacity: 0.5;
  font-size: 10px;
  grid-row: 3/4;
  grid-column: 2/2;
  margin-left: 0.2rem;
}

.side-buttons-wrapper {
  display: grid;
  padding: 1rem 1rem 1rem 0rem;
  row-gap: 1.5rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  justify-items: start;
  border-bottom: 1px solid #333333;
  background-color: rgb(23, 23, 37);
}

.side-button-homePage {
  width: 100%;
  padding: 4px 15px !important;
  border: none !important;
  display: grid !important;
  grid-template-rows: 1fr;
  grid-template-columns: min-content 1fr;
  justify-content: start;
}

.side-button-homePage-c13nLogo-wrapper {
  grid-row: 1/2;
  grid-column: 1/2;
  width: 14px;
  height: 20px;
}

.side-button-homePage-c13nLogo {
  width: 14px;
  height: 20px;
}

.side-button-homePage-text {
  grid-row: 1/2;
  grid-column: 2/3;
  width: 180px;
  display: grid;
  justify-content: start;
  padding-left: 1rem;
  height: 20px;
}

.side-button-searchUsers {
  width: 100%;
  padding: 4px 15px !important;
  border: none !important;
  display: grid !important;
  grid-template-rows: 1fr;
  grid-template-columns: min-content 1fr;
  justify-content: start;
}

.side-button-searchUsers-searchLogo-wrapper {
  grid-row: 1/2;
  grid-column: 1/2;
  width: 14px;
  height: 20px;
}

.side-button-searchUsers-searchLogo {
  width: 14px;
  height: 20px;
}

.side-button-searchUsers-text {
  grid-row: 1/2;
  grid-column: 2/3;
  width: 180px;
  display: grid;
  justify-content: start;
  padding-left: 1rem;
  height: 20px;
}

.side-button-groupChat {
  width: 100%;
  padding: 4px 15px !important;
  border: none !important;
  display: grid !important;
  grid-template-rows: 1fr;
  grid-template-columns: min-content 1fr;
  justify-content: start;
}

.side-button-groupChat-groupsearchlogo-wrapper {
  grid-row: 1/2;
  grid-column: 1/2;
  width: 14px;
  height: 20px;
}

.side-button-groupChat-groupsearchlogo {
  width: 14px;
  height: 20px;
}

.side-button-groupChat-text {
  grid-row: 1/2;
  grid-column: 2/3;
  width: 180px;
  display: grid;
  justify-content: start;
  padding-left: 1rem;
  height: 20px;
}

.side-contacts-wrapper {
  text-align: left;
  display: grid;
  gap: 1.5rem;
  grid-template-rows: repeat(2, minmax(min-content, max-content)) auto minmax(
      min-content,
      max-content
    );
  padding: 0.8rem 0.8rem 0.6rem 0.4rem;
}

.discussionsList {
  max-height: calc(100vh - 475px);
  gap: 2rem;
  overflow-y: scroll;
  --scrollbar-width: 0.5rem;
  padding-right: 1.25rem;
  --mask-image-content: linear-gradient(
    to bottom,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat;
  scrollbar-width: thin;
  scrollbar-color: currentColor transparent;
}

.discussionsList::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.discussionsList::-webkit-scrollbar-thumb {
  background-color: currentColor;
  border-radius: 9999rem;
}

.discussionsList::-webkit-scrollbar-track {
  background-color: transparent;
}

.side-filter {
  align-self: start;
}

.userContainer {
  z-index: 99;
  transition: 0.3s;
  margin: 0px;
  padding: 5px;
  border: none;
  width: 100%;
}

.SideListItem {
  margin-left: 15px;
  float: right;
  cursor: pointer;
}

.sideContactUserWrapper {
  cursor: pointer;
  width: 150px;
}

.sideContactUserWrapperB {
  display: inline;
}

.sideContactUserWrapperDiv {
  font-size: 80%;
}

.sideDropdown {
  display: inline-block;
  padding: 5px;
  cursor: pointer;
}

.side-input-filter {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.side-input-version {
  font-size: 10px;
  place-self: end stretch;
  grid-row: 4/5;
}
