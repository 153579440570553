.group-chat {
  display: grid;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
}

.group-chat-header {
  height: 4rem;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
}

.group-chat-header-text {
  font-size: 18px;
  grid-column-start: 2;
}

.group-chat-main {
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
}

.group-chat-searchAlias-wrapper {
  display: block;
  width: 90%;
  margin-top: 3%;
  margin-bottom: 2%;
  max-height: 50vh;
  overflow-y: auto;
}

.group-chat-search {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr min-content;
}

.group-chat-input {
  grid-row: 1/2;
  grid-column: 1/2;
}

.group-search-btn {
  grid-row: 1/2;
  grid-column: 2/3;
}

.error-validation {
  color: #b40e0e;
}

.userSearchContainer {
  padding: 10px;
}

.userSearchContainerDv {
  user-select: text;
  font-weight: bold;
}

.group-chat-address {
  display: inline;
  user-select: text;
  font-size: 75%;
}

.group-chat-addressDv {
  display: inline-block;
}

.group-chat-containerDv {
  user-select: text;
  font-weight: bold;
}

.group-chat-containerDv-address {
  display: inline;
  user-select: text;
  font-size: 75%;
}
