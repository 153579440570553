.connection-screen-dv {
  position: fixed;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: rgb(23, 23, 35);
  text-align: center;
  padding: 50px;
  font-size: 25px;
}
