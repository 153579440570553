.welcomePage {
  display: grid;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
}

.welcomePage-header {
  height: 4rem;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
}

.welcomePage-header-text {
  font-size: 18px;
  grid-column-start: 2;
}

.welcomePage-logo {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr repeat(2, minmax(min-content, max-content)) 1fr;
  justify-items: center;
  align-items: center;
}

.interactiveLogoC13n {
  width: 40vh;
  text-shadow: 0 0 4px #00fff2;
  grid-row: 2/3;
}

.interactiveLogoProgramize {
  height: 25vh;
  text-shadow: 0 0 4px #00fff2;
  grid-row: 3/4;
}

@media screen and (max-width: 930px) {
  .interactiveLogoC13n {
    width: 30vh;
  }

  .interactiveLogoProgramize {
    height: 20vh;
  }
}

@media screen and (max-width: 930px) {
  .interactiveLogoC13n {
    width: 20vh;
  }

  .interactiveLogoProgramize {
    height: 14vh;
  }
}
