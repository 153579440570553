.user-settings {
  display: grid;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
}

.user-settings-header {
  height: 4rem;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
}

.user-settings-header-text {
  font-size: 22px;
  grid-column-start: 2;
}

.user-settings-main {
  overflow: auto;
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
}

.user-settings-discButton {
  display: block;
  float: right;
}

.user-settings-button {
  border: none;
}

.user-settings-form {
  overflow-wrap: break-word;
}

.user-settings-collapse {
  margin-right: 5px;
}

.user-settings-formSelect {
  margin-right: 5px;
  max-width: 200px;
}

.user-settings-github {
  border: none;
}
