.user-funds {
  display: grid;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
}

.user-funds-header {
  height: 4rem;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
}

.user-funds-header-text {
  font-size: 22px;
  grid-column-start: 2;
}

.users-funds-main {
  overflow: auto;
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
}

.user-funds-form {
  margin-left: 15px;
}
