.contacts {
  display: grid;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
}

.contacts-header {
  height: 4rem;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
}

.contacts-header-text {
  font-size: 22px;
  grid-column-start: 2;
}

.contacts-body {
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
}

.userSearchContainer {
  padding: 10px;
}

.userSearchContainerAfter {
  user-select: text;
  font-weight: bold;
}

.userSearchContainerAddress {
  display: inline;
  user-select: text;
  font-size: 75%;
}

.userSearchContainerButton {
  position: relative;
  display: inline;
}

.userDeleteOutlined {
  font-size: 125%;
}

.userDeleteOutlinedButton {
  position: relative;
  display: inline;
}

.contacts-list {
  width: 95%;
}
